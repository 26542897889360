import { graphql, PageProps } from "gatsby";
import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { Heading01 } from "../components/Heading01";
import { Heading03 } from "../components/Heading03";
import { Layout } from "../components/Layout/index";
import bgimgPc from "../images/h1/h1recruit.png";
import bgimgSp from "../images/h1/h1recruit_sp.png";

const RecruitPage: React.VFC<PageProps<GatsbyTypes.RecruitIndexQuery>> = () => {
  return (
    <Layout title="採用情報" description="ミスギの採用情報ページです。">
      <Heading01
        label="RECRUIT"
        smallLabel="採用情報"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <Breadcrumb labels={[{ url: "", text: "採用情報" }]} />
      {/* WORKING */}
      <div className="l-container-sm mt-10 pc:mt-16">
        <Heading03 label="働き方と福利厚生" bigLabel="WORKING" />
        <dl className="mb-10 pc:my-16">
          {/* 行ここから */}
          <div className="relative list-none border-b border-solid border-[#E0E0E0]">
            <div className="flex flex-col py-10 pc:flex-row pc:items-center pc:py-12 pc:pr-4">
              <dt className="flex-shrink-0 text-sm font-semibold text-primary">
                休日休暇
              </dt>
              <dd className="mt-4 text-base text-dark pc:mt-0 pc:ml-[100px]">
                完全週休2日制・年間休日120日以上・夏季休暇・年末年始休暇
              </dd>
            </div>
          </div>
          {/* 行ここまで */}
          {/* 行ここから */}
          <div className="relative list-none border-b border-solid border-[#E0E0E0]">
            <div className="flex flex-col py-10 pc:flex-row pc:items-center pc:py-12 pc:pr-4">
              <dt className="flex-shrink-0 text-sm font-semibold text-primary">
                勤務時間
              </dt>
              <dd className="mt-4 text-base text-dark pc:mt-0 pc:ml-[100px]">
                <p>固定時間制 9:00 ~ 18:00</p>
                <br />
                <p>
                  実働8時間・休憩1時間。完全土日祝日休みです。
                  ただし、年に1・2度ですが展示会に出展の為に土日出勤になる場合があります。
                  その場合は代休を取って頂きます。
                </p>
                <br />
                {/* --------丸印用クラスコピペ用--------- */}
                {/* relative pl-5 before:absolute before:left-0
                before:top-[.5em] before:h-2 before:w-2 before:rounded-full
                before:bg-primary before:content-[''] */}
                {/*---------丸印用クラスここまで------- */}
                <p
                  className="relative pl-5 before:absolute before:left-0
                before:top-[.5em] before:h-2 before:w-2 before:rounded-full
                before:bg-primary before:content-['']"
                >
                  弊社では、基本的に管理職も含め、遅くても18:30には退社できる環境で、基本的にほぼ残業はありません。
                </p>
              </dd>
            </div>
          </div>
          {/* 行ここまで */}
          {/* 行ここから */}
          <div className="relative list-none border-b border-solid border-[#E0E0E0]">
            <div className="flex flex-col py-10 pc:flex-row pc:items-center pc:py-12 pc:pr-4">
              <dt className="flex-shrink-0 text-sm font-semibold text-primary">
                福利厚生
              </dt>
              <dd className="mt-4 text-base text-dark pc:mt-0 pc:ml-[100px]">
                雇用保険 厚生年金 健康保険 労災保険 交通費支給あり
                <br />
                <p className="mt-3 text-base">
                  ※感染症対策として以下の取り組みを行っています
                </p>
                <ul>
                  <li className="mt-2">・消毒液設置</li>
                  <li className="mt-2">・定期的な換気</li>
                  <li className="mt-2">・レジ等に飛沫防止の仕切り設置</li>
                </ul>
              </dd>
            </div>
          </div>
          {/* 行ここまで */}
        </dl>
      </div>
      {/* RECRUITMENT */}
      <div className="bg-bggrey py-10 pc:pt-16 pc:pb-32">
        <div className="l-container-sm">
          <Heading03 label="募集職種" bigLabel="RECRUITMENT" />
          <div className="mt-8 pc:mt-14">
            <div className="rounded-2xl bg-white p-8 pc:p-14">
              <h3 className="text-xl font-bold pc:text-2xl">
                商社やホームセンター等への法人営業
              </h3>
              <dl className="mt-8">
                <dt className="text-[16px] text-primary">お仕事内容</dt>
                <dd className="mt-4 text-base">
                  <p
                    className="relative pl-5 font-bold before:absolute before:left-0
                before:top-[.5em] before:h-2 before:w-2 before:rounded-full
                before:bg-primary before:content-['']"
                  >
                    ホームセンター(HC)へのルート 営業
                  </p>
                  <p className="mt-2 pl-5">
                    現在、弊社商品を販売して頂いている店舗への定期訪問営業と各ホームセンター本部での商談です。
                    各HC店舗への訪問営業は、お店の売場担当者からの要望に応じての対応や簡単な売場の整理が中心になります。
                    また、各HC本部では、担当バイヤーと期間限定のセールや年間取組などについての商談が中心になります。
                  </p>
                  <p
                    className="relative mt-6 pl-5 font-bold before:absolute before:left-0
                before:top-[.5em] before:h-2 before:w-2 before:rounded-full
                before:bg-primary before:content-['']"
                  >
                    エクステリア・建築金物などの 商社へのルート営業
                  </p>
                  <p className="mt-2 pl-5">
                    外構エクステリア商社や金物商社又は、それらの販売店への営業活動になります。
                  </p>
                  <p className="mt-6">
                    どちらも入社からの一定期間（約3ヶ月間）は、先輩社員が同行しやさしく丁寧に指導するので安心です。
                  </p>
                </dd>
                <dt className="mt-10 text-primary">応募資格・条件</dt>
                <dd className="mt-2 text-base">
                  <p>高校卒以上・普通自動車運転免許（AT限定可）</p>
                  <p className="mt-2">既卒・第二新卒歓迎・未経験OK</p>
                </dd>
                <dt className="mt-10 text-primary">雇用形態</dt>
                <dd className="mt-2 text-base">正社員</dd>
                <dt className="mt-10 text-primary">募集人数</dt>
                <dd className="mt-2 text-base">２名以上</dd>
                <dt className="mt-10 text-primary">給与</dt>
                <dd className="mt-2 text-base">
                  <p>月給 27万円 ～ 35万円（※想定年収 340万円 ～ 450万円）</p>
                  <p className="mt-2">
                    上記額は、営業手当+固定残業(40時間分・合計約60,000円。但し、金額は月収により変動します)が含まれております。固定残業時間を超過した場合は超過分の残業代を全額支給いたします。
                  </p>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RecruitPage;

export const query = graphql`
  query RecruitIndex {
    wpPage(id: { eq: "cG9zdDoxMQ==" }) {
      companyAcf {
        message {
          catch
          text
        }
        mission {
          catch
          text
        }
      }
    }
  }
`;
